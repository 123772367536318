import { MainMenuItem } from './layout/main-menu.service';
import { environment } from '../environments/environment';
import { routerSegments } from '@consts/routes';
import { PermissionModel } from '@models/permission.model';

const developerRole =
  environment.env === 'DEV' || environment.env === 'localNoKube' || environment.env === 'dev-hosted' ? ['user'] : ['admin'];

export const mainMenuItems: MainMenuItem[] = [
  {
    displayName: 'Edges',
    path: 'operator/create',
    class: 'ui-icon-device',
    roles: ['operator'],
    permissions: [],
  },
  {
    displayName: 'Edit core',
    path: 'operator/edit',
    class: 'ui-icon-settings',
    roles: ['operator'],
    permissions: [],
  },
  {
    displayName: 'White List',
    path: `${routerSegments.operator}/${routerSegments.whitelist}`,
    class: 'ui-icon-user',
    roles: ['operator'],
    permissions: [],
  },
  {
    displayName: 'Cameras',
    path: routerSegments.locationV2,
    roles: ['user'],
    class: 'ui-icon-camera',
    permissions: [],
  },
  {
    displayName: 'Search',
    path: `${routerSegments.search}`,
    roles: ['user'],
    disabled: false,
    class: 'ui-icon-search',
    permissions: [PermissionModel.Permissions.SearchView],
  },
  {
    displayName: 'Alerts',
    path: routerSegments.monitoring,
    roles: ['user'],
    disabled: false,
    class: 'ui-icon-notifications',
    permissions: [],
  },
  {
    displayName: 'Dashboards',
    path: `${routerSegments.dashboards}`,
    roles: ['user'],
    disabled: false,
    class: 'ui-icon-dashboard',
    permissions: [PermissionModel.Permissions.DashboardView],
  },
  {
    displayName: 'Walls',
    path: routerSegments.walls,
    roles: ['user'],
    disabled: false,
    class: 'ui-icon-wall',
    permissions: [],
  },

  // {
  //   displayName: 'Alerts View',
  //   icon: 'list',
  //   path: routerSegments.alertEventsMonitoring,
  //   roles: ['user'],
  //   disabled: false,
  // },
  {
    displayName: 'Archives',
    path: routerSegments.archive,
    roles: ['user'],
    disabled: false,
    class: 'ui-icon-archive',
    permissions: [],

  },
  // {
  //   displayName: 'Settings',
  //   svg: 'settings',
  //   path: `${routerSegments.settings}`,
  //   roles: ['user'],
  //   disabled: false,
  // },
  {
    displayName: 'Pricing',
    path: `${routerSegments.pricing}`,
    roles: ['sales'],
    disabled: false,
    class: 'ui-icon-attach_money',
    permissions: [],
  },
];
//todo consult with Edan and remove
export const developerMenuItems: MainMenuItem[] = [
  {
    displayName: 'Environment Info',
    icon: 'info',
    path: 'development/info',
    roles: developerRole,
    permissions: [],
  },
  {
    displayName: 'Heartbeats',
    icon: 'favorite',
    path: 'development/heartbeat',
    roles: developerRole,
    permissions: [],
  },
  {
    displayName: 'Analytics',
    icon: 'fingerprint',
    path: 'development/analytics',
    roles: developerRole,
    permissions: [],
  },
  {
    displayName: 'Alerts',
    icon: 'warning',
    path: 'development/alerts',
    roles: developerRole,
    permissions: [],
  },
  {
    displayName: 'Motion-Vectors',
    icon: 'directions_walk',
    path: 'development/motion-vectors',
    roles: developerRole,
    permissions: [],
  },
  {
    displayName: 'Thumbnails',
    icon: 'image',
    path: 'development/thumbnails',
    roles: developerRole,
    permissions: [],
  },
  {
    displayName: 'Logs',
    icon: 'info',
    path: 'logs',
    roles: developerRole,
    permissions: [],
  },
  {
    displayName: 'Search Canvas',
    icon: 'bug_report',
    path: 'development/search-canvas',
    roles: developerRole,
    permissions: [],
  },
  {
    displayName: 'Search Grid',
    icon: 'bug_report',
    path: 'development/search-grid',
    roles: developerRole,
    permissions: [],
  },
];
