import { WallAlertMonitoringFilters, WallModel, WallModelV2 } from '@models/wall.model';
import { AlertOrdering, WallAlertListPosition, WallLayoutType } from '@enums/wall.enum';
import { AlertsV2DisplaySettings, AlertsV2PicInPicPosition } from '@models/alerts-v2.model';
import CameraTileConfig = WallModelV2.CameraTileConfig;
import AlertTileConfig = WallModelV2.AlertTileConfig;
import WallLayout = WallModelV2.WallLayout;

export const defaultCameraTileConfig: CameraTileConfig = {
  titleColor: '#0EA5E9',
  displayCameraName: true,
};

export const defaultAlertTileConfig: AlertTileConfig = {
  sound: true,
  display: AlertsV2DisplaySettings.LIVE,
  titleColor: '#0EA5E9',
  picInPic: AlertsV2DisplaySettings.PLAYBACK,
  picInPicPos: AlertsV2PicInPicPosition.TOP_LEFT,
  duration: 30000,
};

export const defaultWall: WallModel = {
  name: '',
  isPrivate: false,
  alertPosition: WallAlertListPosition.NONE,
  rotateDuration: 0,
  alertOrdering: AlertOrdering.FREE_TILE,
  layoutType: WallLayoutType.CAMERAS,
  sets: [
    {
      name: 'Set 1',
      tiles: new Array(9).fill({
        cameraTileConfig: defaultCameraTileConfig,
        alertTileConfig: defaultAlertTileConfig,
        data: null,
      }),
      layout: WallLayout.GRID_9,
    },
  ],
  alerts: null,
};

export const initialAlertsMonitoringFilters: WallAlertMonitoringFilters = {
  frequency: 5,
  eventCameraIds: [],
};


