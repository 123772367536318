import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NotificationActions } from '@states/notification/notification.action-types';
import { MultiPlaybackActions } from '@states/multi-playback/multi-playback.action-types';
import { SharedActions } from '@states/shared/shared.action-types';
import { EdgeActions } from '@states/edge/edge.action-types';
import { WallActions } from '@states/wall/wall.action-types';
import * as AlertMonitoringActions from '@states/alert-monitoring/alert-monitoring.actions';
import { EdgeHeartbeatPulsationActions } from '@states/edge-heartbeat-pulsation/edge-heartbeat-pulsation-types';
import { CameraHeartbeatPulsationActions } from '@states/camera-heartbeat-pulsation/camera-heartbeat-pulsation-types';
import { AnalyticHeartbeatPulsationActions } from '@states/analytic-heartbeat-pulsation/analytic-heartbeat-pulsation-types';
import { StorageHeartbeatPulsationActions } from '@states/storage-heartbeat-pulsation/storage-heartbeat-pulsation-types';
import { SubstreamHeartbeatPulsationActions } from '@states/substream-heartbeat-pulsation/substream-heartbeat-pulsation-types';
import { SmartStorageHeartbeatPulsationActions } from '@states/smart-storage-heartbeat-pulsation/smart-storage-heartbeat-pulsation-types';
import { DeviceStatusActions } from '@states/device-status/device-status.actions-types';

export const extModules = [
  StoreDevtoolsModule.instrument({
    maxAge: 100,
    actionsBlocklist: ['[Shared] Set Inactivity Time',
      NotificationActions.getNotificationById.type,
      NotificationActions.getNotificationByIdSuccess.type,
      MultiPlaybackActions.setMove.type,
      MultiPlaybackActions.setDefault.type,
      SharedActions.doNothing.type,
      EdgeActions.ClassifyIpAddress.type,
      WallActions.newAlertSocketReceived.type,
      WallActions.getAlertsMonitoringSuccess.type,
      WallActions.archiveAlertSuccess.type,
      AlertMonitoringActions.setNewAlerts.type,
      AlertMonitoringActions.archiveAlertSuccess.type,
      AlertMonitoringActions.newAlertsValidation.type,
      AlertMonitoringActions.refreshAlertsByAutoAcknowledgesAndFrequency.type,
      MultiPlaybackActions.setData.type,
      EdgeHeartbeatPulsationActions.GetEdgePulsationStatus.type,
      EdgeHeartbeatPulsationActions.GetEdgePulsationStatusSuccess.type,
      CameraHeartbeatPulsationActions.GetCameraPulsationStatus.type,
      CameraHeartbeatPulsationActions.GetCameraPulsationStatusSuccess.type,
      AnalyticHeartbeatPulsationActions.GetAnalyticPulsationStatus.type,
      AnalyticHeartbeatPulsationActions.GetAnalyticPulsationStatusSuccess.type,
      StorageHeartbeatPulsationActions.GetStoragePulsationStatus.type,
      StorageHeartbeatPulsationActions.GetStoragePulsationStatusSuccess.type,
      SubstreamHeartbeatPulsationActions.GetSubstreamPulsationStatusSuccess.type,
      SmartStorageHeartbeatPulsationActions.GetSmartStoragePulsationStatusSuccess.type,
      DeviceStatusActions.setCamerasStatus.type,
      DeviceStatusActions.setEdgesStatus.type,
      DeviceStatusActions.setLocationStatus.type,
      DeviceStatusActions.calculateEdgeStatus.type,
      SharedActions.setInactivityCountdown.type,
    ],
  connectInZone: true}),
];
