import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { LiveStreamModels } from '@models/live-stream.model';
import { VideoService } from '../../../development/video.service';
import { untilDestroyed } from '@ngneat/until-destroy';
import { VisibilityChanged } from '../../directives/visibility-change.directive';
import { MultiPlaybackActions } from '@states/multi-playback/multi-playback.action-types';
import { Store } from '@ngrx/store';
import { HlsListeners } from 'hls.js';
import { Observable } from 'rxjs';
import { MultiPlaybackSelectors } from '@states/multi-playback/multi-playback.selector-types';

export const HEALTHCHECK_INTERVAL_TIME = 10000;
export const MAX_RECOVERIES = 6;
export const DEFAULT_EXTEND_TIME = 120000;

@Component({
  selector: 'app-player-base',
  templateUrl: './player-base.component.html',
  styleUrl: './player-base.component.scss',
})
export abstract class PlayerBaseComponent implements OnDestroy, OnInit {

  public selectDragging$: Observable<boolean> = this.store$.select(MultiPlaybackSelectors.selectDragging)
    .pipe(untilDestroyed(this));

  @ViewChild('player', { static: true }) public playerObj: ElementRef;

  @Input() public isRespectRatio: boolean = true;
  @Input() edgeId: string;
  @Input() cameraId: string;
  @Input() locationId: string;
  @Input() resolution: LiveStreamModels.StreamResolution = LiveStreamModels.StreamResolution.HQ;
  @Input() placeholder = false;
  @Input() inactive = false;
  @Input() autostart = true;
  @Input() public enableHealthCheck = true;
  @Input() public accessToken: string;
  @Input() cameraView = false;

  @Output() _setPlaceholder: EventEmitter<{ set: boolean, playerObj?: ElementRef }> = new EventEmitter<{ set: boolean, playerObj?: ElementRef }>();
  @Output() showRetry: EventEmitter<void> = new EventEmitter<void>();
  @Output() setErrorMsg: EventEmitter<string> = new EventEmitter<string>();
  @Output() setLoader: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() setInactive: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() playing: EventEmitter<string> = new EventEmitter<string>();
  @Output() qualityChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public health = {
    prevTime: 0,
    interval: null,
  };
  userPause = false;

  // Recovery params
  private recoverCount = 0;
  private lastRecovery: number;

  recover = true;

  public started = false;
  protected windowHidden = false;
  protected isViewInitCompleted = false;
  protected placeholderInterval;
  protected eventListeners: { [key: string]: EventListenerOrEventListenerObject } = {};

  protected lastCurrentTime: number = 0;

  public playbackErrors = {
    internal: false,
    maxSessions: false,
    error: null,
    statusCode: null,
  };

  public maskTimeUpdate = false;
  public dragging = false;

  protected videoEventListeners: { [key: string]: EventListenerOrEventListenerObject } = {};

  canPlayTimeout: any;

  protected _destroyed = false;

  protected constructor(
    protected store$: Store,
    protected cd: ChangeDetectorRef,
    protected videoService: VideoService) {
  }

  ngOnInit(): void {
    this.selectDragging$.subscribe(dragging => {
      this.dragging = dragging;
    });
  }

  ngOnDestroy(): void {
    this.clearHealthCheck();
    if (this.health?.interval) {
      clearInterval(this.health?.interval);
    }
    this._destroyed = true;
  }

  public clearHealthCheck() {
    if (this.health?.interval) {
      clearInterval(this.health?.interval);
    }

    this.health = {
      prevTime: 0,
      interval: null,
    };
  }

  public get currentTime() {
    return this.playerObj?.nativeElement?.currentTime;
  }

  public healthCheck() {
    if (this.userPause) {
      return;
    }

    if (this.health?.prevTime >= this.currentTime) {
      console.log('[WEBRTC] health check failed');
      this.onError('health check failed');
      this.clearHealthCheck();
    }
    this.health.prevTime = this.currentTime;
  }

  async onError(reason?: string) {
    this.pause();
    this.setPlaceholder();
    this.setErrorMsg.emit(reason);

    if (this.recoverCount < MAX_RECOVERIES) {
      await this.stop();
      this.play(this.playback ? this.ts : null);
      if (Date.now() - this.lastRecovery < 20000) {
        this.recoverCount++;
        if (this.recoverCount === MAX_RECOVERIES) {
          this.setLoader.emit(false);
          this.showRetry.emit();
        }
      } else {
        this.recoverCount = 0;
      }
      this.lastRecovery = Date.now();
    }
  }

  public get isLive() {
    return this.videoService.isLive;
  }

  public get playback() {
    return !this.isLive;
  }

  public get ts() {
    return this.videoService.ts;
  }

  public startHealthCheck() {
    if (this.health.interval) {
      clearInterval(this.health.interval);
    }
    this.health.prevTime = this.video?.currentTime;
    this.health.interval = setInterval(() => {
      this.healthCheck();
    }, HEALTHCHECK_INTERVAL_TIME);
  }

  public setPlaceholder() {
    this._setPlaceholder.emit({ set: true, playerObj: this.playerObj });
  }

  public _removePlaceholder() {
    this._setPlaceholder.emit({ set: false });
  }

  protected initVideoControls() {

    this.videoService.timelineDragStart$.pipe(untilDestroyed(this))
      .subscribe(_ => {
        this.pause();
      });

    this.videoService.timelineDragEnd$.pipe(untilDestroyed(this))
      .subscribe(async (event) => {
        if (this.wasPlaying || this.cameraView) {
          const offline = await this.videoService.isOffline();
          if (offline && this.cameraId === this.videoService.primaryCameraId) {
            this.videoService.OnEmulate();
          } else {
            this.play(this.ts);
          }
        }
      });

    this.videoService.timeChanged$.pipe(untilDestroyed(this))
      .subscribe(event => {
        // this.play(this.ts);
      });

    this.videoService.pause$.pipe(untilDestroyed(this))
      .subscribe(_ => {
        this.pause();
      });

    this.videoService.stop$.pipe(untilDestroyed(this))
      .subscribe(_ => {
        // console.log('stop$');
        this.stop();
      });

    this.videoService.play$.pipe(untilDestroyed(this))
      .subscribe(_ => {
        this.resetErrors();
        if (this.isLive) {
          this.play();
          return;
        }
        this.play(this.ts);
      });
  }

  public get video() {
    return this.playerObj.nativeElement as HTMLVideoElement;
  }

  initPlayer() {
    this.video.muted = true;
    this.video.controls = false;
  }

  protected setDragging(dragging: boolean) {
    this.store$.dispatch(MultiPlaybackActions.setDragging({ dragging }));
  }

  protected playVideo() {
    const TIMEOUT_DURATION = 10000;

    // Function to handle video play
    const handlePlay = () => {
      if (this.videoEventListeners['canplay']) {
        this.video.removeEventListener('canplay', this.videoEventListeners['canplay']);
        delete this.videoEventListeners['canplay'];
      }
      clearTimeout(this.canPlayTimeout);
      this.video.play()
        .then(() => {
          this.setLoader.emit(false);
          this.started = true;
          this.cd.detectChanges();
          this.playing.emit();
          this.setMove(0.5, this.ts);
          this.setDragging(false);
          this._removePlaceholder();
          this.maskTimeUpdate = false;

        })
        .catch(error => {
          console.error('Error occurred while trying to play the video:', error);
        });
    };

    // Check if the video is already playing
    if (!this.video.paused) {
      console.log('Video is already playing.');
      this.setLoader.emit(false);
      this.started = true;
      this.cd.detectChanges();
      this.playing.emit();
      // if (this.isHlsPlayback(this.hlsPlaybackTs)) {
      //   this.setMove(0.5, this.hlsPlaybackTs + this.video?.currentTime);
      // }
      this.setMove(0.5, this.ts);

      this.setDragging(false);
      this._removePlaceholder();
      return;
    }

    // Wait for the video to be ready
    this.videoEventListeners['canplay'] = handlePlay;
    this.video.addEventListener('canplay', this.videoEventListeners['canplay'], { once: true });

    // Set a timeout to handle cases where canplay isn't triggered
    this.canPlayTimeout = setTimeout(() => {
      this.video.removeEventListener('canplay', this.videoEventListeners['canplay']);
      delete this.videoEventListeners['canplay'];
      // Handle fallback logic here
      // this.onError('Playback failed, timeout reached');
    }, TIMEOUT_DURATION);
  }

  protected clearVideo(): void {
    const videoElement = this.playerObj.nativeElement;
    if (videoElement) {
      // Remove video event listeners
      Object.keys(this.videoEventListeners)
        .forEach(event => {
          videoElement.removeEventListener(event, this.videoEventListeners[event]);
        });
      this.videoEventListeners = {};

      videoElement.pause();
      videoElement.src = '';
      videoElement.load(); // Reset the video element
      videoElement.srcObject = null;
    }
  }

  protected removeAllVideoEventListeners(): void {
    const videoElement = this.playerObj.nativeElement;
    Object.keys(this.eventListeners)
      .forEach(event => {
        videoElement.removeEventListener(event, this.eventListeners[event]);
      });
  }

  public get isPlaying(): boolean {
    const video = this.video;
    return this.inactive || !!(video && video.currentTime > 0 && !video.paused && !video.ended && video.readyState > 2);
  }

  public get wasPlaying(): boolean {
    const video = this.video;
    return this.inactive || !!(video && video.currentTime > 0 && !video.ended && video.readyState > 2);

  }

  // Abstract methods
  abstract pause(): void;

  abstract stop(): void;

  abstract play(ts?: number): void;

  abstract extend(): Promise<void>;

  abstract resetState(): void;

  abstract checkHiddenDocument(visibilityChanged: VisibilityChanged): Promise<void>;

  abstract changeResolution(resolution: LiveStreamModels.StreamResolution): void;

  // Update the video time
  public onTimeUpdate(event: Event): void {
    if (this.maskTimeUpdate || this.dragging) {
      return;
    }
    const delta = this.currentTime - this.lastCurrentTime;
    if (this.ts) {
      this.setMove(0.5, this.ts + delta * 1000);
      this.lastCurrentTime = this.currentTime;
    }
  }

  setMove(percentage: number, timestamp: number) {
    this.store$.dispatch(MultiPlaybackActions.setMove({ move: { percentage, timestamp } }));
  }

  resetErrors() {
    this.playbackErrors = {
      internal: false,
      maxSessions: false,
      error: null,
      statusCode: null,
    };
  }
}
