import { ActionReducerMap } from '@ngrx/store';
import { AnalyticsState, analyticsStateReducer } from '@states/analytics/analytics.reducer';
import { OrganizationState, organizationStateReducer } from '@states/organization/organization.reducer';
import { SharedState, sharedStateReducer } from '@states/shared/shared.reducer';
import { InviteState, inviteStateReducer } from '@states/invite/invite.reducer';
import MenuState, { menuStateReducer } from '@states/menu/menu.reducer';
import { AlertEventsState, alertEventsStateReducer } from '@states/alert-events/alert-events.reducer';
import { SettingsState, settingsStateReducer } from '@states/settings/settings.reducer';
import { CameraEditState, cameraEditStateReducer } from '@states/camera-edit/camera-edit.reducer';
import { AlertMonitoringState, alertMonitoringStateReducer } from '@states/alert-monitoring/alert-monitoring.reducer';
import { MultiSearchState, multiSearchStateReducer } from '@states/multi-search/multi-search.reducer';
import { EdgeEditState, edgeEditStateReducer } from '@states/edge-edit/edge-edit.reducer';
import { PlaybackState, playbackStateReducer } from '@states/playback/playback.reducer';
import { ArchiveState, archiveStateReducer } from '@states/archive/archive.reducer';
import { GrantedAccessState, grantedAccessStateReducer } from '@states/granted-access/granted-access.reducer';
import { StatsState, statsStateReducer } from '@states/stats/stats.reducer';
import { UserSettingsState, userSettingsStateReducer } from '@states/user-settings/user-settings.reducer';
import { TrackObjectState, trackObjectStateReducer } from '@states/track-object/track-object.reducer';
import { TerminalState, terminalStateReducer } from '@states/terminal/terminal.reducer';
import { GeneralState, generalStateReducer } from '@states/general/general.reducer';
import { ActivityLogState, activityLogStateReducer } from '@states/activity-log/activity-log.reducer';
import { ThumbnailsState, thumbnailsStateReducer } from '@states/thumbnails/thumbnails.reducer';
import { DevTeamState, devTeamStateReducer } from '@states/dev-team/dev-team.reducer';
import { dateTimeRangePickerReducer, DateTimeRangePickerState } from '../shared/ui-kit/ui-datetime-range-picker/store/ui-datetime-range-picker.reducer';
import { VariableState, variableStateReducer } from '@states/variable/variable.reducer';
import { WorkspaceState, workspaceStateReducer } from '@states/workspace/workspace.reducer';
import { CamerasState, camerasStateReducer } from '@states/cameras/cameras.reducer';
import { SearchState, searchStateReducer } from '@states/search/search.reducer';
import { WebRtcState, webRtcStateReducer } from '@states/webrtc/webrtc.reducer';
import { NotificationState, notificationStateReducer } from '@states/notification/notification.reducer';
import { MultiPlaybackState, multiPlaybackStateReducer } from '@states/multi-playback/multi-playback.reducer';
import { PricingState, pricingStateReducer } from '@states/pricing/pricing.reducer';
import { AlertsV2State, alertsV2StateReducer } from '@states/alerts-v2/alerts-v2.reducer';
import { PeopleState, peopleStateReducer } from '@states/people/people.reducer';
import { EdgePulsationState, edgePulsationStatusReducer } from '@states/edge-heartbeat-pulsation/edge-heartbeat-pulsation.reducer';
import { CameraPulsationState, cameraPulsationStatusReducer } from '@states/camera-heartbeat-pulsation/camera-heartbeat-pulsation.reducer';
import { AnalyticPulsationState, analyticPulsationStatusReducer } from '@states/analytic-heartbeat-pulsation/anaytic-heartbeat-pulsation.reducer';
import { StoragePulsationState, storagePulsationStatusReducer } from '@states/storage-heartbeat-pulsation/storage-heartbeat-pulsation.reducer';
import { ApiKeyState, apiKeyStateReducer } from '@states/api-key/api-key.reducer';
import { LocationEditState, locationEditStateReducer } from '@states/location-edit/location-edit.reducer';
import { OrganizationUsersState, organizationUsersStateReducer } from '@states/organization-users/organization-users.reducer';
import { PulsationModels } from '@models/pulsation.model';
import StorageState = PulsationModels.StorageState;
import { storageStateReducer } from '@states/storage/storage.reducer';
import { AlertLogsState, alertLogsStateReducer } from '@states/alert-logs/alert-logs.reducer';
import { DoorsState, doorsStateReducer } from '@states/doors/doors.reducer';
import { CustomEventsState, customEventsStateReducer } from '@states/custom-events/custom-events.reducer';
import { SmartStoragePulsationState, smartStoragePulsationStatusReducer } from '@states/smart-storage-heartbeat-pulsation/smart-storage-heartbeat-pulsation.reducer';
import { SubstreamPulsationState, substreamPulsationStatusReducer } from '@states/substream-heartbeat-pulsation/substream-heartbeat-pulsation.reducer';
import { UserGroupState, userGroupStateReducer } from '@states/user-groups/user-groups.reducer';
import { webRTCActiveSessionReducer, WebrtcActiveSessionState } from '@states/webrtc-active-sessions/webrtc-active-sessions.reducer';
import { VehiclesState, vehiclesStateReducer } from '@states/vehicles/vehicles.reducer';
import { OrgGroupsState, orgGroupsStateReducer } from '@states/org-groups/org-groups.reducer';
import { PermissionGroupState, permissionGroupStateReducer } from '@states/group/group.reducer';
import { permissionsStateReducer } from '@states/permissions/permissions.reducer';
import { DashboardState, dashboardStateReducer } from '@states/dashboard/dashboard.reducer';
import { DeviceStatusState, deviceStatusStateReducer } from '@states/device-status/device-status.reducer';
import { authenticationReducer, AuthenticationState } from '@states/authentication/authentication.reducer';
import { userReducer, UserState } from '@states/user/user.reducer';
import { locationReducer, LocationState } from '@states/location/location.reducer';
import { EdgeState, locationEdgeReducer } from '@states/edge/edge.reducer';
import { CameraState, locationEdgeCameraReducer } from '@states/camera/camera.reducer';
import { CertificationState, certificationStateReducer } from '@states/certifications/certifications.reducer';
import IdentityState, { identityStateReducer } from '@states/identity/identity.reducer';
import { AppleTvState, appleTvStateReducer } from '@states/apple-tv/apple-tv.reducer';
import { ApplePulsationState, applePulsationStatusReducer } from '@states/apple-heartbeat-pulsation/apple-heartbeat-pulsation.reducer';
import { UserDeviceState, userDeviceStateReducer } from '@states/device/device.reducer';
import { IntegrationsState, integrationsStateReducer } from '@states/integrations/integrations.reducer';
import { UserMfaState, userMfaStateReducer } from '@states/mfa/mfa.reducer';
import { FloorState, floorStateReducer } from '@states/floors/floor.reducer';
import { WebhookState, webhookStateReducer } from '@states/webhook/webhook.reducer';
import { DCHPState, dhcpStateReducer } from '@states/dhcp/dhcp.reducer';
import { ExternalStorageState, externalStorageStateReducer } from '@states/external-storage/external-storage.reducer';
import { WallV2State, wallV2StateReducer } from '@states/wall-v2/wall-v2.reducer';
import { YoutubeState, youtubeStateReducer } from '@states/youtube/youtube.reducer';
import { AccessDoorsState, accessDoorsStateReducer } from '@states/access-doors/access-doors.reducer';
import { CameraSettingsState, cameraSettingsStateReducer } from '@states/camera-settings/camera-settings.reducer';
import { HomeState, homeStateReducer } from '@states/home/home.reducer';
import { RouteState, routeStateReducer } from '@states/route/route.reducer';
import { AlarmState, alarmStateReducer } from '@states/alarm/alarm.reducer';
import { ScheduleState, scheduleStateReducer } from '@states/schedule/schedule.reducer';
import { EdgeMetadataState, edgeMetadataStateReducer } from '@states/edge-metadata/edge-metadata.reducer';

export interface AppState {
  accessDoorsState: AccessDoorsState;
  activityLogState: ActivityLogState;
  alertEventsState: AlertEventsState;
  alertLogsState: AlertLogsState;
  alertMonitoringState: AlertMonitoringState;
  alertsV2State: AlertsV2State;
  analyticPulsationState: AnalyticPulsationState;
  analyticsState: AnalyticsState;
  applePulsationState: ApplePulsationState;
  appleTvState: AppleTvState;
  apiKeyState: ApiKeyState;
  archiveState: ArchiveState;
  authenticationState: AuthenticationState;
  cameraEditState: CameraEditState;
  cameraPulsationState: CameraPulsationState;
  cameraSettingsState: CameraSettingsState;
  cameraState: CameraState;
  camerasState: CamerasState;
  certificationState: CertificationState;
  customEventsState: CustomEventsState;
  dashboardState: DashboardState;
  dateTimeRangePickerState: DateTimeRangePickerState;
  devTeamState: DevTeamState;
  dhcpState: DCHPState;
  deviceStatusState: DeviceStatusState;
  doorsState: DoorsState;
  edgeEditState: EdgeEditState;
  edgeMetadataState: EdgeMetadataState;
  edgePulsationState: EdgePulsationState;
  edgeState: EdgeState;
  externalStorageState: ExternalStorageState;
  floorState: FloorState;
  generalState: GeneralState;
  grantedAccessState: GrantedAccessState;
  homeState: HomeState;
  identityState: IdentityState;
  integrationsState: IntegrationsState;
  inviteState: InviteState;
  locationEditState: LocationEditState;
  locationState: LocationState;
  menuState: MenuState;
  multiPlaybackState: MultiPlaybackState;
  multiSearchState: MultiSearchState;
  notificationState: NotificationState;
  organizationState: OrganizationState;
  organizationUsersState: OrganizationUsersState;
  orgGroupsState: OrgGroupsState;
  peopleState: PeopleState;
  permissionGroupState: PermissionGroupState;
  permissionsState: PermissionState;
  playbackState: PlaybackState;
  pricingState: PricingState;
  routeState: RouteState;
  searchState: SearchState;
  settingsState: SettingsState;
  sharedState: SharedState;
  smartStoragePulsationState: SmartStoragePulsationState;
  statsState: StatsState;
  storagePulsationState: StoragePulsationState;
  storageState: StorageState;
  substreamPulsationState: SubstreamPulsationState;
  terminalState: TerminalState;
  thumbnailsState: ThumbnailsState;
  trackObjectState: TrackObjectState;
  userDeviceState: UserDeviceState;
  userGroupState: UserGroupState;
  userMfaState: UserMfaState;
  userSettingsState: UserSettingsState;
  userState: UserState;
  variableState: VariableState;
  vehiclesState: VehiclesState;
  wallV2State: WallV2State;
  webRTCActiveSessionState: WebrtcActiveSessionState;
  webRtcSessionsState: WebRtcState;
  webhookState: WebhookState;
  workspaceState: WorkspaceState;
  youtubeState: YoutubeState;
  alarmState: AlarmState;
  scheduleState: ScheduleState;
}

export const reducers: ActionReducerMap<any> = {
  accessDoorsState: accessDoorsStateReducer,
  activityLogState: activityLogStateReducer,
  alertEventsState: alertEventsStateReducer,
  alertLogsState: alertLogsStateReducer,
  alertMonitoringState: alertMonitoringStateReducer,
  alertsV2State: alertsV2StateReducer,
  analyticPulsationState: analyticPulsationStatusReducer,
  analyticsState: analyticsStateReducer,
  applePulsationState: applePulsationStatusReducer,
  appleTvState: appleTvStateReducer,
  apiKeyState: apiKeyStateReducer,
  archiveState: archiveStateReducer,
  authenticationState: authenticationReducer,
  cameraEditState: cameraEditStateReducer,
  cameraPulsationState: cameraPulsationStatusReducer,
  cameraSettingsState: cameraSettingsStateReducer,
  cameraState: locationEdgeCameraReducer,
  camerasState: camerasStateReducer,
  certificationState: certificationStateReducer,
  customEventsState: customEventsStateReducer,
  dashboardState: dashboardStateReducer,
  dateTimeRangePickerState: dateTimeRangePickerReducer,
  devTeamState: devTeamStateReducer,
  dhcpState: dhcpStateReducer,
  deviceStatusState: deviceStatusStateReducer,
  doorsState: doorsStateReducer,
  edgeEditState: edgeEditStateReducer,
  edgeMetadataState: edgeMetadataStateReducer,
  edgePulsationState: edgePulsationStatusReducer,
  edgeState: locationEdgeReducer,
  externalStorageState: externalStorageStateReducer,
  floorState: floorStateReducer,
  generalState: generalStateReducer,
  grantedAccessState: grantedAccessStateReducer,
  homeState: homeStateReducer,
  identityState: identityStateReducer,
  integrationsState: integrationsStateReducer,
  inviteState: inviteStateReducer,
  locationEditState: locationEditStateReducer,
  locationState: locationReducer,
  menuState: menuStateReducer,
  multiPlaybackState: multiPlaybackStateReducer,
  multiSearchState: multiSearchStateReducer,
  notificationState: notificationStateReducer,
  orgGroupsState: orgGroupsStateReducer,
  organizationState: organizationStateReducer,
  organizationUsersState: organizationUsersStateReducer,
  peopleState: peopleStateReducer,
  permissionGroupState: permissionGroupStateReducer,
  permissionsState: permissionsStateReducer,
  playbackState: playbackStateReducer,
  pricingState: pricingStateReducer,
  routeState: routeStateReducer,
  searchState: searchStateReducer,
  settingsState: settingsStateReducer,
  sharedState: sharedStateReducer,
  smartStoragePulsationState: smartStoragePulsationStatusReducer,
  statsState: statsStateReducer,
  storagePulsationState: storagePulsationStatusReducer,
  storageState: storageStateReducer,
  substreamPulsationState: substreamPulsationStatusReducer,
  terminalState: terminalStateReducer,
  thumbnailsState: thumbnailsStateReducer,
  trackObjectState: trackObjectStateReducer,
  userDeviceState: userDeviceStateReducer,
  userGroupState: userGroupStateReducer,
  userMfaState: userMfaStateReducer,
  userSettingsState: userSettingsStateReducer,
  userState: userReducer,
  variableState: variableStateReducer,
  vehiclesStateReducer: vehiclesStateReducer,
  wallV2State: wallV2StateReducer,
  webRTCActiveSessionState: webRTCActiveSessionReducer,
  webRtcSessionsState: webRtcStateReducer,
  webhookState: webhookStateReducer,
  workspaceState: workspaceStateReducer,
  youtubeState: youtubeStateReducer,
  alarmState: alarmStateReducer,
  scheduleState: scheduleStateReducer,
};

