import { KeyValuePairs, SQSMsgInfo } from '../core/interfaces';
import { LocationModel } from '../locations/location.model';
import { Region } from '@enums/shared.enum';
import { Zones } from '../shared/ui-kit/ui-zone-selector/ui-zone-selector.model';
import { Edge } from '../edge/edge.model';
import { AlertEventLineCrossing, AlertEventTrafficControl } from '@models/alert-events.model';
import { CameraStreamCapabilities } from '@models/search-configuration.model';
import { PulsationModels } from '@models/pulsation.model';
import { UiSchedule } from '@models/alerts-v2.model';
import SmartStorageSettings = EdgeCamera.SmartStorageSettings;
import CloudStorageSettings = EdgeCamera.CloudStorageSettings;
import { LiveStreamModels } from '@models/live-stream.model';
import { WallModelV2 } from '@models/wall.model';

export namespace EdgeCamera {

  import WallPositionEvent = WallModelV2.WallPositionEvent;

  export interface EdgeCameraStatus extends Edge.HeatbeatCameraStatus {
    edgeId: string;
    timestamp: string | number;
    // cameraId: string;
    // cameraStatus: CameraStatus;
    // analyticStatus: AnalyticStatus;
    // componentsStatus?: ComponentsStatus;
    // cameraStreamerProgressData?: CameraStreamerProgressData;
    // analyticProgressData?: AnalyticProgressData;
    // cameraOldestStorageTimestamp?: number;
  }


  export interface InputRate {
    max: number;
    min: number;
    avg: number;
    std: number;
  }

  export interface OutputRate {
    max: number;
    min: number;
    avg: number;
    std: number;
  }

  export interface L1Stats {
    lpr_hits: number;
    lpr_miss: number;
  }

  export interface AnalyticProgressData {
    timestamp: any;
    inputRate: InputRate;
    outputRate: OutputRate;
    analyticUpdateRate: number;
    trainingUpdateRate: number;
    l1Stats: L1Stats[];
  }

  export interface CameraStreamerProgressData {
    frameNumber: number;
    format: string;
    fps: number;
    width: number;
    height: number;
    timestamp: any;
  }

  export interface ComponentsStatus {
    input: StreamerComponentStatus;
    hls: StreamerComponentStatus;
    hlsscaled: StreamerComponentStatus;
    hlsscaledhq: StreamerComponentStatus;
    storage: StreamerComponentStatus;
    smartstorage: StreamerComponentStatus;
  }

  export interface CameraItem {
    locationId?: string;
    edgeId?: string;
    cameraId?: string;
    snapshot?: string;
    edgeOnly?: CameraDocument;
    cloudOnly?: CameraCloudOnly;
    playbackSessionId?: string;
    zones?: Zones;
    markedIdx?: number[];
    lineCrossing?: AlertEventLineCrossing | AlertEventTrafficControl;
    trafficControl?: AlertEventTrafficControl;
    locationName?: string;
    events?: number[];
    alertEvents?: WallPositionEvent[];
    lastVideoTimestamp?: number;
    cameraHealth?: CameraHealth;
    deleting?: boolean;
    zonesExclude?: boolean;
    createdAt?: number;
  }

  export interface UploadCameraAssetRequest {
    file: Blob;
    locationId: string;
    edgeId: string;
    cameraId: string;
    asset: CameraAsset;
  }

  export interface CameraAsset {
    filename: string;
  }

  export interface SmartStorageSettings {
    enabled: boolean;
    preEventDuration: number;
    postEventDuration: number;
  }

  export type CloudStorageSchedule = UiSchedule[];

  export interface CloudStorageSettings {
    enabled: boolean;
    cloudRetentionPeriod: number; // in days
    backupStorage?: boolean;
    backupAlerts?: boolean;
    backupEventTypes?: boolean;
    footageSchedule: CloudStorageSchedule;
    uploadSchedule: CloudStorageSchedule;
  }

  export interface ExternalStorageSettings {
    enabled: boolean;
    externalRetentionPeriod: number; // in days
    backupStorage?: boolean;
    backupAlerts?: boolean;
    backupEventTypes?: boolean;
    footageSchedule: CloudStorageSchedule;
    uploadSchedule: CloudStorageSchedule;
  }

  export interface CameraDocument {
    cameraId?: string;
    edgeId?: string;
    locationId?: string;
    name?: string;
    username?: string;
    password?: string;
    networkDetails?: CameraNetworkInfoInterface;
    description?: string;
    lastHeartbeat?: string;
    adminUsername?: string;
    adminPassword?: string;
    profiles?: CameraProfileInterface[];
    maxNumberOfDaysVideoStorage?: number;
    cloudRetention?: number;
    dateAdded?: number;
    storageStartYear?: number;
    storageEndYear?: number;
    cameraDeviceInfo?: CameraDeviceOnvifInfoInterface;
    onvifCompliant?: boolean;
    nonStandardOnvif?: boolean;
    nonStandardOnvifPort?: string;
    ptzSupport?: boolean;
    ptzTimeout?: number;
    ptzSpeedSlow?: number;
    ptzSpeedMedium?: number;
    ptzSpeedFast?: number;
    ptzPort?: number;
    ptzDriver?: PtzDriver;
    ptzProtocol?: PtzProtocol;
    hwDetails?: CameraHWDetailsInterface;
    mainStream?: CameraStreamDocument;
    subStreams?: CameraStreamDocument[];
    subStreamExtra?: CameraStreamDocument;
    assets?: CameraAsset[];
    storageMaxDays?: number;
    storageMaxGB?: number;
    storageSchedule?: UiSchedule[];
    smartStorage?: SmartStorageSettings;
    cloudStorage?: CloudStorageSettings;
    sync?: boolean;
    cameraBrand?: string;
    streamCapabilities?: CameraStreamCapabilities[];
    autoConfigure?: boolean;
    autoConfigureMainstream?: boolean;
    autoConfigureSubstream?: boolean;
    webrtcSQ?: WebRTCConfig;
    webrtcHQ?: WebRTCConfig;
    storageStream?: {
      storageFromSubstream: boolean;
      storageSubstreamIndex?: number;
    };
    disabled?: boolean;
    origName?: string;
    retentionDays?: number;
    entityCounter?: number;
    externalStorage?: ExternalStorageSettings;
    additionalEnabled?: boolean;
    analyticMode?: AnalyticModes;
    sdCardEnabled?: boolean;
    metadata?: string;
  }

  export enum AnalyticModes {
    Enabled,
    ThumbnailsOnly,
    Disabled
  }

  interface WebRTCConfig {
    webrtcStartBitrate: number,
    webrtcMaxBitrate: number,
    webrtcMinBitrate: number,
    webrtcFec: boolean,
    webrtcRetransmission: boolean,
    webrtcMaxWidth: number,
    webrtcMaxHeight: number,
    webrtcMaxFramerate: number,
    iFrameInterval: number,
  }

  export type CameraDocumentMap = KeyValuePairs<CameraDocument>;
}

export enum CameraAssetStatus {
  UPLOADING,
  UPLOADED,
  CONFIRMED,
}

export interface CameraAddState {
  cameraDetails?: EdgeCamera.CameraDocument;
  loading?: boolean;
  token?: string;
  error?: string;
  snapshot?: string;
  snapshotLoaded?: boolean;
  username?: string;
  password?: string;
  autoConfigure?: boolean;
  createSuccess?: boolean;
  createErrorMsg?: string;
  existingStreamCapabilities?: CameraStreamCapabilities[];
  suggestedStreamCapabilities?: CameraStreamCapabilities[];
  streamCapabilities?: CameraStreamCapabilities[];
};

export interface Camera {
  cameraId?: string;
  cameraBrand?: CameraBrands;
  isCameraBrand?: boolean;
  username?: string;
  password?: string;
  ipAddress?: string;
  isOnvif?: boolean;
  mac?: string;
  macAddress?: string;
  port?: string;
  name?: string;
  status?: number;
  description?: string;
  onvifCompliant?: boolean;
  path?: string;
  onvifData?: CameraDeviceOnvifInfoInterface;
  multiple?: boolean;
  addState?: CameraAddState;
}

export enum CameraCreateStatus {
  LOADING,
  SUCCESS,
  ERROR,
}

export interface Substream {
  connectionString: string;
  connected: boolean;
  loading: boolean;
  session?: string;
  width?: number;
  height?: number;
}

export interface FileCamera extends Camera {
  snapshotUrl?: string;
  loading?: boolean;
  success?: boolean;
  error?: boolean;
  errorMsg?: string;
  session?: string;
  camera?: EdgeCamera.CameraDocument;
  createStatus?: CameraCreateStatus;
  createErrorMsg?: string;
}

export interface FileCameraCreate {
  camera?: EdgeCamera.CameraDocument;
  defaultSnapshot?: string;
}

export interface CameraListResponse {
  cameras: CameraMap;
}

export type CameraMap = KeyValuePairs<Camera>;

export interface CameraNetworkInfoInterface {
  status?: CameraStatus;
  ipAddress?: string;
  port?: number;
  macAddress?: string;
  dhcp?: boolean;
  forceTcp?: boolean;
}

export interface CameraDeviceOnvifInfoInterface {
  urn: string;
  name: string;
  hardware: string;
  location: string;
  types: string[];
  xaddrs: string[];
  scopes: string[];
}

export enum CameraBrands {
  Hikvision = 'Hikvision',
  Uniview = 'Uniview',
  Axis = 'Axis',
  Lumana = 'Lumana',
  Lumix = 'Lumix.AI',
  Unknown = 'Unknown',
  Nvidia = 'Nvidia'
}

export interface CameraBrandhInterface {
  cameraBrand: CameraBrands;
  isCameraBrand?: boolean;
}

export type CameraDeviceInfoInterface = CameraDeviceOnvifInfoInterface &
  CameraNetworkInfoInterface &
  CameraBrandhInterface;

export interface CameraHWDetailsInterface {
  Manufacturer: string;
  Model: string;
  FirmwareVersion: string;
  SerialNumber: string;
  HardwareId: string;
}

export interface CameraProfileInterface {
  token: string;
  name: string;
  snapshot: string;
  stream: {
    udp: string;
    http: string;
    rtsp: string;
  };
  video: {
    source: {
      token: string;
      name: string;
      bounds: {
        width: number;
        height: number;
        x: number;
        y: number;
      };
    };
    encoder: {
      token: string;
      name: string;
      resolution: {
        width: number;
        height: number;
      };
      quality: number;
      framerate: number;
      bitrate: number;
      encoding: string;
    };
  };
  audio: {
    source: {
      token: string;
      name: string;
    };
    encoder: {
      token: string;
      name: string;
      bitrate: number;
      samplerate: number;
      encoding: string;
    };
  };
  ptz: {
    range: {
      x: {
        min: number;
        max: number;
      };
      y: {
        min: number;
        max: number;
      };
      z: {
        min: number;
        max: number;
      };
    };
  };
}

export enum DiscoveryMessageType {
  SearchDevices,
  SearchDevicesGetCameraDetails,
  GetIpAddress,
  GetNetworkUsage,
  GetVideoFFProbe,
  OnvifCommand,
  ChangeSubstreamList,
  GetCameraBrand,
  ConfigureCamera,
  GetCameraInfo,
  SearchDevicesByIpRange,
  TestConnectionString
}

export enum ScanType {
  Onvif = 'onvif',
  RTSP = 'rtsp',
  Network = 'network',
}

export interface SearchDevicesByIpRangeData {
  ipStart: string;
  ipEnd: string;
  port: number;
}

export interface SearchDevicesScanCamerasRequest {
  edgeId: string;
  msgType: DiscoveryMessageType.SearchDevicesByIpRange;
  data: SearchDevicesByIpRangeData;
}

interface DiscoveryDataMsg {
  msgType: DiscoveryMessageType;
  data: string;
}

export interface GetCameraDetailsMsg {
  msgType: DiscoveryMessageType;
  macAddress: string;
  username: string;
  password: string;
}

export enum CameraStatus {
  Online,
  Offline,
  Streaming,
  Stopped,
  NotAuthenticated,
  StreamNotFound,
  Unknown,
  Init,
  Connected,
  Error,
}

export const CameraStatusToString: { [Property in CameraStatus]: string } = {
  [CameraStatus.Online]: 'Online',
  1: 'Offline',
  2: 'Streaming',
  3: 'Stopped',
  4: 'Not Authenticated',
  5: 'Stream not found',
  6: 'Unknown',
  7: 'Init',
  8: 'Connected',
  9: 'Error',
};

export enum AnalyticStatus {
  Online,
  Offline,
}

export const AnalyticStatusString: { [Property in AnalyticStatus]: string } = {
  [AnalyticStatus.Online]: 'AI On',
  [AnalyticStatus.Offline]: 'AI Off',
};

export enum LocationStatus {
  Online,
  Offline,
  Unhealthy
}


export const LocationStatusString: { [Property in LocationStatus]: string } = {
  [LocationStatus.Online]: LocationStatus[LocationStatus.Online],
  [LocationStatus.Offline]: LocationStatus[LocationStatus.Offline],
  [LocationStatus.Unhealthy]: LocationStatus[LocationStatus.Unhealthy],
};


enum CameraStreamInputType {
  H264,
  H265,
  MJPEG,
  H264Plus,
  H265Plus,
  Main,
  Storage,
  File,
  Ignore,
}

enum CameraStreamInputStreamProtocolType {
  Rtsp,
  Http,
  Udp,
}

interface CameraStreamScaleFFMPEGCmd {
  fps?: number;
  width?: number;
  height?: number;
  protocol?: CameraStreamInputStreamProtocolType;
  maxBitrate?: number;
  avgBitrate?: number;
  bufferSize?: number;
  hls_allow_cache?: boolean;
  hlsListSize?: number;
  hlsTime?: number;
  hlsPreset?: string;
}

interface CameraStreamHistoryFFMPEGCmd {
  fps?: number;
  width?: number;
  height?: number;
  protocol?: CameraStreamInputStreamProtocolType;
  maxBitrate?: number;
  avgBitrate?: number;
  bufferSize?: number;
  hls_allow_cache?: boolean;
  hlsListSize?: number;
  hlsTime?: number;
  hlsPreset?: string;
}

interface CameraStreamFFMPEGCmd {
  fps?: number;
  width?: number;
  height?: number;
  protocol?: CameraStreamInputStreamProtocolType;
  analyzationDuration?: number;
  probeSize?: number;
  userCameraTimestamps?: boolean;
  hls_allow_cache?: boolean;
  hlsListSize?: number;
  hlsTime?: number;
  hlsPreset?: string;
}

enum CameraStreamBitrateType {
  Variable,
  Constant,
}

export enum RateControlType {
  CBR = 'cbr',
  VBR = 'vbr',
}

export enum RateControlPresetLevel {
  Disable = 'disable',
  UltraFast = 'ultra_fast',
  Fast = 'fast',
  Medium = 'medium',
  Slow = 'slow',
}

interface CameraStreamBase {
  inputType: CameraStreamInputType;
  width?: number;
  height?: number;
  fps?: number;
  bitrateType?: CameraStreamBitrateType;
  maxBitrate?: string;
  avgBitrate?: string;
  bufferSize?: string;
  hls_allow_cache?: boolean;
  hlsListSize?: number;
  hlsTime?: number;
  hlsPreset?: string;
  hlsFragmentDuration?: number;
  hlsMaxLiveFragments?: number;
  hlsMaxStoredFragments?: number;
  hlsLiveDirect?: boolean;
  hlsHQScaledFragmentDuration?: number;
  hlsHQScaledMaxLiveFragments?: number;
  hlsHQScaledMaxStoredFragments?: number;
  hlsHQScaledWidth?: number;
  hlsHQScaledHeight?: number;
  hlsHQScaledBitrateBps?: number;
  hlsHQScaledRateControlType?: RateControlType;
  hlsHQScaledPeakBitrateBps?: number;
  hlsHQScaledIFrameInterval?: number;
  hlsHQScaledPresetLevel?: RateControlPresetLevel;
  hlsSQScaledFragmentDuration?: number;
  hlsSQScaledMaxLiveFragments?: number;
  hlsSQScaledMaxStoredFragments?: number;
  hlsSQScaledWidth?: number;
  hlsSQScaledHeight?: number;
  hlsSQScaledBitrateBps?: number;
  hlsSQScaledRateControlType?: RateControlType;
  hlsSQScaledPeakBitrateBps?: number;
  hlsSQScaledIFrameInterval?: number;
  hlsSQScaledPresetLevel?: RateControlPresetLevel;
  hlsSQliveScaleFragmentDurationIFrame?: boolean;
  hlsHQliveScaleFragmentDurationIFrame?: boolean;
}

type CameraStreamHistory = CameraStreamBase;

type CameraStreamLiveScale = CameraStreamBase & {
  protocol?: CameraStreamInputStreamProtocolType;
  port?: number;
  connectionString?: string;
  analyzationDuration?: number;
  probeSize?: number;
  useWallClockTimestamps?: boolean;
};

export type CameraStreamMain = CameraStreamBase & {
  protocol: CameraStreamInputStreamProtocolType;
  port: number;
  connectionString: string;
  analyzationDuration?: number;
  probeSize?: number;
  useWallClockTimestamps?: boolean;
};

export interface CameraStreamDocument {
  main: CameraStreamMain;
  mainScale: CameraStreamLiveScale;
  history: CameraStreamHistory;
  thumbnailsEnabled: boolean;
  thumbnailsDuration: number;
  motionVectorsEnabled: boolean;
  motionVectorsDuration: number;
  profile?: CameraProfileInterface;
  skipPing?: boolean;
  uploadMaxbitrate?: number; // Quality of service
  storageMaxHours: number;
  cloudRetentionMaxHours: number;
  videoAnalyticsEnabled: boolean;
  videoAnalyticsMinSensativity: number;
  videoAnalyticsMaxFps: number;
  videoAnalytics?: string[]; // VideoAnalyticDocument ids in the order they need to run
  audioAnalyticsEnabled: boolean;
  audioAnalytics?: string[]; // AudioAnalytic ids in the order they need to run
}

export interface UpdateCameraStreamDocument {
  main: Partial<CameraStreamMain>;
  mainScale: Partial<CameraStreamLiveScale>;
  history: Partial<CameraStreamHistory>;
  thumbnailsEnabled: boolean;
  thumbnailsDuration: number;
  motionVectorsEnabled: boolean;
  motionVectorsDuration: number;
  profile?: Partial<CameraProfileInterface>;
  skipPing?: boolean;
  uploadMaxbitrate?: number; // Quality of service
  storageMaxHours: number;
  cloudRetentionMaxHours: number;
  videoAnalyticsEnabled: boolean;
  videoAnalyticsMinSensativity: number;
  videoAnalyticsMaxFps: number;
  videoAnalytics?: string[]; // VideoAnalyticDocument ids in the order they need to run
  audioAnalyticsEnabled: boolean;
  audioAnalytics?: string[]; // AudioAnalytic ids in the order they need to run
}

export interface UpdateCameraDocument {
  cameraId?: string;
  name?: string;
  username?: string;
  password?: string;
  networkDetails?: Partial<CameraNetworkInfoInterface>;
  description?: string;
  lastHeartbeat?: string;
  adminUsername?: string;
  adminPassword?: string;
  maxNumberOfDaysVideoStorage?: number;
  cloudRetention?: number;
  dateAdded?: number;
  storageStartYear?: number;
  storageEndYear?: number;
  cameraDeviceInfo?: Partial<CameraDeviceOnvifInfoInterface>;
  onvifCompliant?: boolean;
  nonStandardOnvif?: boolean;
  nonStandardOnvifPort?: string;
  ptzSupport?: boolean;
  hwDetails?: Partial<CameraHWDetailsInterface>;
  mainStream?: Partial<UpdateCameraStreamDocument>;
  subStreams?: Partial<UpdateCameraStreamDocument>[];
  subStreamExtra?: Partial<UpdateCameraStreamDocument>;
  sync?: boolean;
  cameraBrand?: string;
  streamCapabilities?: CameraStreamCapabilities[];
  storageMaxDays?: number;
  storageMaxGB?: number;
  storageSchedule?: UiSchedule[];
  storageStream?: {
    storageFromSubstream: boolean;
    storageSubstreamIndex?: number;
  };
  smartStorage?: SmartStorageSettings;
  cloudStorage?: CloudStorageSettings;
  disabled?: boolean;
  timezone?: string;
}

export interface CameraEdgeDetails {
  location: LocationModel.LocationItem;
  edgeId: string;
  edgeName: string;
}

// export interface CameraUpdateRequest {
//   name: string;
//   description: string;
// }

export interface CameraUpdateRequest {
  locationId: string;
  edgeId: string;
  cameraId: string;
  update: Partial<UpdateCameraDocument>;
}

export interface CameraUpdateHlsRequest {
  locationId: string;
  edgeId: string;
  cameraId: string;
  hlsConfig: Partial<LiveStreamModels.HLS>;
}

export interface CameraUpdatePTZRequest {
  locationId: string;
  edgeId: string;
  cameraId: string;
  update: UpdateCameraPTZDocument;
}

export enum PtzDriver {
  Onvif,
  Vivotek
}

export enum PtzProtocol {
  HTTP,
  HTTPS
}

export interface UpdateCameraPTZDocument {
  ptzSupport: boolean;
  ptzSpeedSlow: number;
  ptzSpeedMedium: number;
  ptzSpeedFast: number;
  ptzTimeout: number;
  ptzPort?: number;
  ptzDriver?: PtzDriver;
  ptzProtocol?: PtzProtocol;
  cameraDeviceInfo?: Partial<CameraDeviceOnvifInfoInterface>;
}

export interface CameraStartRequest {
  locationId: string;
  edgeId: string;
  cameraId: string;
  hq?: boolean;
}

export interface CameraStartWebrtcPlaybackRequest {
  locationId: string;
  edgeId: string;
  cameraId: string;
  sessionId?: string;
  ts?: number;
}

export interface CameraStopWebrtcPlaybackRequest {
  locationId: string;
  edgeId: string;
  cameraId: string;
  sessionId?: string;
}

export interface CameraStopRequest {
  edgeId: string;
  cameraId: string;
}

export interface CameraEdgeOnly {
  cameraId?: string;
  macAddress: string;
  storageStartYear: number;
  storageEndYear: number;
  username: string;
  password: string;
  ipAddress: string;
  port: string;
  cameraConnectionString: string;
}

export interface CameraCloudOnly {
  detailsFetched?: boolean;
  defaultSnapshot?: string;
  hlsConfig?: LiveStreamModels.HLS;
  coords?: { lat: number, lng: number };
  address?: string;
}

export interface CameraStreamExistsRequest {
  edgeId: string;
  cameraId: string;
  sessionId?: string;
  scale?: boolean;
  local?: boolean;
  region?: Region;
}

export interface LocalStreamExistResponse {
  exist: boolean;
  atime: string;
  ctime: string;
  mtime: string;
  atimeMs: number;
  ctimeMs: number;
  mtimeMs: number;
}

export interface CameraLiveViewResponse extends SQSMsgInfo {
}

export interface GetCameraDetailsManuallyMsg {
  isOnvif?: boolean;
  ipAddress?: string;
  port?: number;
  username?: string;
  password?: string;
  snapshotWidth?: number;
  snapshotHeight?: number;
  onvifData?: CameraDeviceOnvifInfoInterface;
  cameraBrand?: string;
}

export interface GetOnvifCameraDetailsManuallyMsg extends GetCameraDetailsManuallyMsg {
  xaddr?: string;
  profile?: number;
}

export interface GetRTSPCameraDetailsManuallyMsg extends GetCameraDetailsManuallyMsg {
  connectionString?: string;
}

export type GetCameraDetailsCamera = GetRTSPCameraDetailsManuallyMsg & GetOnvifCameraDetailsManuallyMsg;

export enum StreamerComponentStatus {
  Available = 'Available',
  Unavailable = 'Unavailable',
}

export interface CameraHealth {
  cameraId?: string;
  timestamp?: number;
  oldestVideoTimestamp?: string | number,
  width?: number,
  height?: number,
  substreamWidth?: number,
  substreamHeight?: number,
  fps?: {
    avg: number,
    max: number,
    spec: number
  },
  bitrateKbps?: {
    avg: number,
    max: number,
    spec: number
  },
  substreamFps?: {
    avg: number,
    max: number,
    spec: number
  },
  substreamBitrateKbps?: {
    avg: number,
    max: number,
    spec: number
  },
  resolutionSpecMin?: string,
  substreamResolutionSpecMax?: string,
  fpsAvgOutOfSpec?: boolean,
  fpsMaxOutOfSpec?: boolean,
  bitrateAvgOutOfSpec?: boolean,
  bitrateMaxOutOfSpec?: boolean,
  resolutionOutOfSpec?: boolean,
  substreamFpsAvgOutOfSpec?: boolean,
  substreamFpsMaxOutOfSpec?: boolean,
  substreamBitrateAvgOutOfSpec?: boolean,
  substreamBitrateMaxOutOfSpec?: boolean,
  substreamResolutionOutOfSpec?: boolean,
  substreamExists?: boolean,
}

export interface GetLastVideoDates {
  camerasHealth: CameraHealth[];
}

export enum CameraTestAction {
  ConnectionTest = 'connectionTest',
  PingTest = 'pingTest'
}

export interface CameraTestMsgBase {
  action: CameraTestAction;
  snapshotWidth: number;
  snapshotHeight: number;
  isExistingCamera: boolean;
}

export interface ExistingCameraTestMsg extends CameraTestMsgBase {
  cameraId?: string;
}

export interface CameraTestMsg extends CameraTestMsgBase {
  ip?: string;
  port?: string;
  username?: string;
  password?: string;
  connectionString?: string;
}

export interface CameraTestData {
  ip?: string;
  port?: string;
  username?: string;
  password?: string;
  connectionString?: string;
  ping?: boolean;
  tcp?: boolean;
}


export interface ProperFitting {
  max: number,
  min: number,
  num: number,
  cameraId: string,
  edgeId: string,
  timePerPicture: number,
  status: PulsationModels.ComponentStatus,
  daysToGoal: number
}

export interface ExtendedStreamData {
  averageFps?: number, //avg_fps
  averageBitrate?: number, //avg_bitrate
  totalKeyFrames?: number, //total_key_frames
  width?: number,
  height?: number,
  codecName?: string, //codec_name
  profile?: string, //profile
  totalDuration?: number, //total_duration
  totalSize?: number, //total_size
  totalFrames?: number, //num_of_frames
  averageKeyFrameDistance?: number, //avg_key_frame_distance
  pixelFormat?: string, //pix_fmt
  realFrameRate?: string, //r_frame_rate eg "15/1"
  hasBframes?: boolean, //has_b_frames
  bitratePerSecond?: number, //Don't know what this is
  maxBitrate?: number, //max_bitrate
  minBitrate?: number, //min_bitrate
  averageBitratePerSecond?: number, //avg_bitrate_per_second
  codecType?: string, //codec_type
  codedWidth?: number,
  codedHeight?: number,
  averageFrameRate?: string, //avg_frame_rate eg "15/1"
}

export interface StreamSetting {
  pixelsCount: number;
  fps: number;
  bitrateKbps: number;
  videoQuality: number;
  compression: number;
  iframeFactor: number;
}
