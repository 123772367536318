<ng-container *ngrxLet="selectSelectedAlert$;let alert">
  <ng-container *ngIf="alert;else loader">


    <div class="alert-wrapper">
      <div class="alert-preview">
        <div class="alert-inner">
          <div *ngIf="alert?.event?.selectedFlow?.category !== alertsCaterogy.Status else statusChangedTemplate"
               class="snapshots"
          >
            <span class="camera-name flex-row start-center">
              <ui-camera-status [cameraId]="alert.cameraId" [iconSizePx]="16" class="ui-icon"></ui-camera-status>
              {{ cameraLookup[alert.cameraId]?.edgeOnly?.name }}
            </span>
            <div class="slider p-r" fxLayout="row" fxLayoutAlign="center center" (mouseenter)="initThumbnail()">
              <div matRipple
                   (click)="track(alert.idBase, alert.idIndex, alert.cameraId, getSnapshotUrl(true))"
                   *ngIf="alert.idBase && alert.idIndex && alert.type === AnalyticClasses.person && activeImageIndex === 1"
                   class="track-object-button" matTooltip="Track object"
              >
                <div class="icon-16" fxLayoutAlign="center center">
                  <mat-icon svgIcon="track"></mat-icon>
                </div>
              </div>
              <div *ngIf="!snapshotLoaded" style="position: absolute" fxFlexFill>
                <mat-progress-bar
                  mode="indeterminate"
                  class="loader"
                  color="primary"
                  style="height: 100%; width: 100%; opacity: 0.02"
                ></mat-progress-bar>
              </div>
              <!--              <ui-snapshot-v2 #snapshot [src]="getSnapshotUrl()" *ngIf="snapshotV2"-->
              <!--                              (click)="playback()"-->
              <!--                              [intervalMs]="1000"-->
              <!--                              (onLoaded)="snapshotLoaded = true"-->
              <!--                              class="w-100 h-100 c-p"-->
              <!--              >-->
              <!--              </ui-snapshot-v2>-->

              <ui-snapshot @fadeOut *ngIf="!removeSnapshot" [src]="getSnapshotUrl()"
                           (click)="playback()"
                           [interval]="1000"
                           (load)="snapshotLoaded = true"
                           class="w-100 h-100 c-p"
              >
              </ui-snapshot>
              <div *ngIf="!previewEnabled" #histWrapper class="preview-timeline">
                <app-thumbnail-histogram #thumbHistogram
                                         [parent]="histWrapper"
                                         [start]="alert.timestamp - 32000"
                                         [end]="alert.timestamp + 32000"
                                         [cameraId]="alert.cameraId"
                                         [edgeId]="alert.edgeId"
                                         [preview]="true"
                                         [isAlertNow]="true"
                                         class="w-100"
                ></app-thumbnail-histogram>
              </div>
              <!--              <div *ngIf="alert?.thumbnails?.length" class="slider-control-wrapper">-->
              <!--                <span [class.active]="!activeImageIndex" class="slider-control" (click)="slideTo(0)"></span>-->
              <!--                <span *ngFor="let thumbnail of alert.thumbnails;let i = index" class="slider-control"-->
              <!--                      [class.active]="activeImageIndex === i+1" (click)="slideTo(i+1)"-->
              <!--                ></span>-->
              <!--              </div>-->

              <app-thumbnail
                class="w-100 h-100 alert-preview-thumbail"
                [class.hide-preview]="!previewEnabled"
                *ngIf="previewEnabled && thumb"
                [thumbnailTemplate]="ThumbnailTemplate.TEMPLATE3"
                [showEventsCounter]="false"
                [optionsInput]="thumb?.options"
                [edgeId]="thumb?.edgeId"
                [cameraId]="thumb?.cameraId"
                (click)="$event.preventDefault(); playback();"
                [thumbnailsData]="thumbData"
                [alertTs]="alert.timestamp"
                [mainThumbnail]="alert.mainThumbnail"
                [alertPreview]="true"
              ></app-thumbnail>

            </div>
          </div>

          <ng-template #statusChangedTemplate>
            <div class="slider">
              <ng-container [ngSwitch]="alert.flowType">
                <ng-container *ngSwitchCase="statusTypes.Edge">
                  <img class="slider-template" src="assets/edge-status.png"/>
                </ng-container>
                <ng-container *ngSwitchCase="statusTypes.Camera">
                  <img class="slider-template" src="assets/camera-status.svg"/>
                </ng-container>
                <ng-container *ngSwitchCase="statusTypes.Analytic">
                  <img class="slider-template" src="assets/analytic-status.svg"/>
                </ng-container>

              </ng-container>
            </div>
          </ng-template>

          <div class="alert-info-container">
            <div fxLayout="row"
                 fxLayoutAlign="space-between center"
                 class="alert-info"
                 *ngrxLet="archivedCounter$; let archivedCounter"
            >
              <div class="name flex-row start-center">
                <ui-truncated-html style="max-width: 250px;">{{ alert?.eventName }}</ui-truncated-html>
              </div>
              <div class="actions svg-fill-gray" fxLayout="row" fxLayoutAlign="space-between center">
              <span *ngIf="(!!this.alert?.archivedAt && archivedCounter<30  && archivedCounter>0)"
                    class="border-icon counter-btn"
                    [class.disabled]="!(isAckPermission|async)"
                    [matTooltip]="'This alert will be archived in ' + archivedCounter + '. Click to cancel.'"
                    (click)="cancelArchive()"
              >
              {{ archivedCounter }}
              </span>
                <ng-container *ngIf="!(!!this.alert?.archivedAt && archivedCounter<30  && archivedCounter>0) && isAckPermission|async">
                <span *ngIf="dateLessThanNow(alert)"
                      class="border-icon ui-icon-acked-alert icon-hover"
                      matTooltip="Unacknowledge alert"
                      (click)="archiveAlert(false)"
                >
                </span>
                  <span *ngIf="!dateLessThanNow(alert)"
                        class="ui-icon-none-acked-alert border-icon icon-hover"
                        matTooltip="Acknowledge alert"
                        (click)="archiveAlert(true)"
                  >
                </span>
                </ng-container>
                <div matTooltip="Playback" style="display: inherit;">
                <span [class.disabled]="!alert.cameraId"
                      class="ui-icon-play border-icon icon-hover"
                      (click)="playback()"
                ></span>
                </div>
                <div style="display: inherit;"
                     [matTooltip]="(!alert.cameraId || getEdgePulsationStatus !== edgeHeartBeatStatuses.Online || getCameraPulsationStatus !== edgeHeartBeatStatuses.Online)?'Camera is offline now':'Live view'"
                >
                <span
                  [class.disabled]="(!alert.cameraId || getEdgePulsationStatus !== edgeHeartBeatStatuses.Online || getCameraPulsationStatus !== edgeHeartBeatStatuses.Online)"
                  class="ui-icon-camera border-icon icon-hover"
                  (click)="liveStream()"

                ></span>
                </div>
                <span *ngIf="isShareAlertPermission|async" [class.disabled]="!alert.cameraId"
                      class="ui-icon-share border-icon icon-hover"
                      matTooltip="Share Alert"
                      (click)="share()"
                ></span>
                <ng-container *ngIf="isDeveloper$|ngrxPush">
                  <mat-icon class="border-icon" matTooltip="Alert structure" (click)="viewStructure()">account_tree
                  </mat-icon>
                  <span class="ui-icon-delete border-icon icon-hover"
                        matTooltip="Delete Alert"
                        (click)="delete()"
                  ></span>
                </ng-container>

              </div>
            </div>

            <div class="flex-row start-center mt-3">
              <span class="ui-icon-mail ui-icon"></span>
              <span class="alert-property" style="overflow: hidden"><ui-truncated-html tooltipPosition="below">{{ alert?.alertMessage }}</ui-truncated-html></span>
            </div>
            <div class="flex-row start-center mt-3">
              <span class="ui-icon-time ui-icon"></span>
              <span class="alert-property">
                <ui-user-settings-date [date]="alert.timestamp"></ui-user-settings-date></span>
            </div>
            <div class="flex-row space-between-center">
              <div class="flex-row start-center mt-3">
                <span class="ui-icon-location ui-icon"></span>
                <span class="alert-property">{{ locationLookup[alert?.selectedCamera?.locationId] | locationAddress }}</span>
              </div>
              <div class="flex-row start-center mt-3">
                <span class="ui-icon" [ngClass]="'ui-icon-'+flowTypeIcon"></span>
                <span class="alert-property"> {{ flowType }}</span>
              </div>
            </div>


          </div>

        </div>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #loader>
  <ui-preloader [color]="preloaderColor.blue"></ui-preloader>
</ng-template>

