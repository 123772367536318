import { ArchiveTimestampLocation } from '@enums/archive.enum';
import { EdgeCamera } from '../../cameras/camera.model';
import { SelectedCamera } from '@models/alert-events.model';
import { AppUser } from '../../user/user.model';
import User = AppUser.User;
import { FlowLookup } from '@models/alerts-v2.model';
import { OnRangeSelectedResult } from '../../shared/ui-kit/ui-calendar-inline/ui-calendar-inline.component';
import { Dictionary } from '@ngrx/entity/src/models';

export enum ArchiveStatus {
  UPLOADING,
  COMPLETED,
  DELETED,
  ERROR,
}

export enum ArchivePreviewStatus {
  PENDING,
  COMPLETED,
  ERROR
}

export const ARCHIVE_MAX_DURATION = 300000; // 5 minutes

export declare module Archive {
  export interface ArchiveDocument {
    _id?: string;
    edgeId: string;
    cameraId: string;
    sessionId?: string;
    start: number;
    end: number;
    status: ArchiveStatus;
    previewStatus?: ArchivePreviewStatus;
    name: string;
    local?: boolean;
    cloud?: boolean;
    progress?: number;
    tags?: FlowLookup[];
    blurFaces?: boolean;
    timestampLocation?: ArchiveTimestampLocation;
    notifications: ArchiveNotificationsResponse;
    createdAt?: string;
    msg?: string;
    progressUpdateTime?: number;
  }

  export interface UpdateArchiveDownload {
    edgeId?: string;
    cameraId?: string;
    sessionId?: string;
    progress?: number;
    uploadProgress?: number;
    status?: ArchiveStatus;
    archiveId?: string;
  }
}


export declare interface ArchiveModel {
  _id?: string;
  name: string;
  selectedCamera: EdgeCamera.CameraItem;
  start: string;
  end: string;
  tags?: FlowLookup[];
  blurFaces?: boolean;
  timestampLocation?: ArchiveTimestampLocation;
  sessionId?: string;
  status?: ArchiveStatus;
  // notifications?: ArchiveNotificationsResponse;
  md5Sum?: string;
  timezone?: string;
  isSmartStorage?: boolean;
  smartStorageInterval?: number[];
}

export declare interface ArchiveNotificationsBase {
  archiveAccessPeriod: number;
  notificationMessage: string;
  emails: string[];
}

export declare interface ArchiveNotificationsRequest extends ArchiveNotificationsBase {
  orgUsers: string[];
}

export declare interface ArchiveNotificationsResponse extends ArchiveNotificationsBase {
  orgUsers: User[];
}

export declare interface ArchiveSearchFilterBase {
  query: string;
  tags: FlowLookup[];
  dateRange: OnRangeSelectedResult;
  status: number[];
}

export declare interface ArchiveResponseFilter extends ArchiveSearchFilterBase {
  selectedCameras: Dictionary<SelectedCamera>;
}

export declare interface ArchiveRequestFilter extends Partial<ArchiveSearchFilterBase> {
  selectedCameras: SelectedCamera[];
  start: number;
  end: number;
}

export declare interface AddArchiveModalConfig {
  disableSelectedCamera: boolean;
  disableSelectDateRange: boolean;
  hasSmartStorage: boolean;
  smartStorageInterval: number[];
}
